/// <reference types="web-bluetooth" />

import React from "react";
import _ from "lodash";

const channelServiceUuid = "19B20000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
const batteryServiceUuid = "19B40000-E8F2-537E-4F6C-D104768A1214".toLowerCase();
const channelCharacteristicUuid =
  "19b20001-e8f2-537e-4f6c-d104768a1214".toLowerCase();
  const batteryCharacteristicUuid = '19B40001-E8F2-537E-4F6C-D104768A1214'.toLowerCase();

export function BluetoothTester() {
  const [flows, setFlows] = React.useState(_.range(8).map(() => "0"));
  const [battery, setBattery] = React.useState("unknown");
  const [channelCharacteristic, setChannelCharacteristic] =
    React.useState<BluetoothRemoteGATTCharacteristic>();
  const [batteryCharacteristic, setBatteryCharacteristic] =
    React.useState<BluetoothRemoteGATTCharacteristic>();

  const handleClick = () => {
    navigator.bluetooth
      .requestDevice({
        filters: [
          { namePrefix: "Nano" },
          { namePrefix: "Arduino" },
          { services: [channelServiceUuid, batteryServiceUuid] },
        ],
      })
      .then((device) => {
        console.log(`Discovered a device!`);
        console.log(device);
        return device.gatt?.connect();
      })
      .then((server) => {
        console.log(`Connected to GATT server`);
        console.log(server);

        // Getting channel
        server!.getPrimaryService(channelServiceUuid).then((service) => {
          service.getCharacteristics().then((crs) => {
            console.log("channel characteristics");
            console.log(crs);
          });

          service.getCharacteristic(channelCharacteristicUuid).then((c) => {
            setChannelCharacteristic(c);
          });
        });
        server!.getPrimaryService(batteryServiceUuid).then((service) => {
          service.getCharacteristics().then((crs) => {
            console.log("battery characteristics");
            console.log(crs);
          });
          service.getCharacteristic(batteryCharacteristicUuid).then((c) => {
            setBatteryCharacteristic(c);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSetFlow = () => {
    const flowsAsNumbers = flows.map((f) => {
      const parsed = parseInt(f);
      return isNaN(parsed) ? 0 : parsed;
    });
    console.log(`sendin these flows to device: ${flowsAsNumbers}`);
    channelCharacteristic?.writeValue(new Uint8Array(flowsAsNumbers));
  };

  const handleGetBattery = () => {
    batteryCharacteristic?.readValue().then(val => {
      console.log(`got val from battery: ${val.buffer}`)
      console.log(val)
      setBattery(`${val.getInt8(0)}`)
    })
  }

  const setFlow = (index: number, value: string) => {
    console.log(`set idx ${index} to ${value}`);
    setFlows((current) => {
      const copy = [...current];
      copy[index] = value;
      return copy;
    });
  };

  return (
    <div>
      <button onClick={handleClick}>Connect</button>

      <div>
        status:{" "}
        {channelCharacteristic ? "channel characteristic found" : "no channel characteristic"}
        <br/>
        {batteryCharacteristic ? "battery characteristic found" : "no battery characteristic"}
      </div>
      <div>
        <b>flows</b>
        {flows.map((current, idx) => (
          <input
            key={idx}
            style={{ width: "50px" }}
            value={current}
            onChange={(e) => setFlow(idx, e.target.value)}
          ></input>
        ))}
        <button onClick={handleSetFlow}>send flows</button>
      </div>
      <div>
        <b>battery %: {battery}</b>
        <button onClick={handleGetBattery}>get battery</button>
      </div>
    </div>
  );
}
